<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="date_from">Date From</label>
            <b-input
              id="date_from"
              v-model="filter.date_from"
              type="date"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="date_from">Date To</label>
            <b-input
              id="date_from"
              v-model="filter.date_to"
              type="date"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Filter by Status</label>
            <b-select
              v-model="filter.status"
              :options="list.statuses"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Filter by Reservation Type</label>
            <b-select
              v-model="filter.reservation_type"
              :options="list.reservationTypes"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Filter by Company</label>
            <b-select
              v-model="filter.company"
              :options="filterCompanies"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <json-excel
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="btn btn-outline-success btn-sm"
            :fetch="myExporter"
            :before-generate="startExport"
            :before-finish="finishExport"
            :disabled="state.exporting || tableSettings.totalRows < 1"
            :fields="exportAs.excel.fields"
            :escape-csv="exportAs.excel.settings.escape"
            :type="exportAs.excel.settings.type"
            :name="excelFileName"
            :header="exportAs.excel.header"
          >
            <span
              v-if="state.exporting"
              class="align-middle"
            >Exporting <i class="ri-loader-2-line" /></span>
            <span
              v-else
              class="align-middle"
            >Export as Excel</span>
          </json-excel>
        </b-col>

      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell(departure)="data">
          <div class="text-nowrap">
            {{ dateTimeFormatterLong(`${data.item.departure_date} ${data.item.departure_time}`) }}
          </div>
        </template>

        <template #cell(arrival)="data">
          <div class="text-nowrap">
            {{ dateTimeFormatterLong(`${data.item.arrival_date} ${data.item.arrival_time}`) }}
          </div>
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="onShowViewForm(row.item)"
            >
              View
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <!-- Modal View Form -->
    <b-modal
      id="modal-view-form"
      scrollable
      no-close-on-backdrop
      title="View Detail"
    >
      <b-card no-body>
        <form
          novalidate
          role="form"
        >
          <b-tabs>
            <b-tab
              title="Reservation Details"
              active
            >
              <b-form-group>
                <label for="created_at">Reserved At</label>
                <b-input
                  id="created_at"
                  v-model="transactionHistory.created_at"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="requested_by">Reserved By</label>
                <b-input
                  id="requested_by"
                  v-model="transactionHistory.user_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="status">Status</label>
                <b-input
                  id="status"
                  v-model="transactionHistory.status"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="company_name">Company</label>
                <b-input
                  id="company_name"
                  v-model="transactionHistory.company_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="organization_name">Organization</label>
                <b-input
                  id="organization_name"
                  v-model="transactionHistory.organization_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="reservation_type">Reservation Type</label>
                <b-input
                  id="reservation_type"
                  v-model="transactionHistory.reservation_type"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="transactionHistory.reservation_type === 'Service Vehicle'">
                <label for="passenger_count">Passenger Count</label>
                <b-input
                  id="passenger_count"
                  v-model="transactionHistory.passenger_count"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="departure">Departure</label>
                <b-input
                  id="departure"
                  v-model="departureDateTime"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="arrival">Arrival</label>
                <b-input
                  id="arrival"
                  v-model="departureDateTime"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="purpose">Purpose</label>
                <b-textarea
                  id="purpose"
                  v-model="transactionHistory.purpose"
                  type="text"
                  rows="6"
                  max-rows="6"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="processed_at">Processed At</label>
                <b-input
                  id="processed_at"
                  v-model="transactionHistory.processed_at"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="processed_by_name">Processed By</label>
                <b-input
                  id="processed_by_name"
                  v-model="transactionHistory.processed_by_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-tab>

            <b-tab
              title="Attachments"
            >
              <b-table
                class="mt-1"
                :fields="attachmentTableSettings.fields"
                :items="transactionHistory.attachments"
                responsive
                striped
                hover
              >
                <template #cell(actions)="row">
                  <div class="text-nowrap">
                    <b-button
                      size="sm"
                      class="mr-1"
                      @click="onPreviewAttachment(row.item)"
                    >
                      Preview
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-tab>

            <b-tab
              v-if="transactionHistory.status === 'Approved' && isDriverVisible"
              title="Driver Details"
            >
              <b-form-group>
                <label for="driver_name">Driver Name</label>
                <b-input
                  id="driver_name"
                  v-model="transactionHistory.driver_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="driver_license_type">License Type</label>
                <b-input
                  id="driver_license_type"
                  v-model="transactionHistory.driver_license_type"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="driver_license_number">License Number</label>
                <b-input
                  id="driver_license_number"
                  v-model="transactionHistory.driver_license_number"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="driver_license_expiry">License Expiry</label>
                <b-input
                  id="driver_license_expiry"
                  v-model="transactionHistory.driver_license_expiry"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-tab>

            <b-tab
              v-if="transactionHistory.status === 'Approved' && isVehicleVisible"
              title="Vehicle Details"
            >
              <b-form-group>
                <label for="vehicle_name">Vehicle Name</label>
                <b-input
                  id="vehicle_name"
                  v-model="transactionHistory.vehicle_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="vehicle_license_plate">License Plate</label>
                <b-input
                  id="vehicle_license_plate"
                  v-model="transactionHistory.vehicle_license_plate"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="vehicle_registration_expiry">Registration Expiry</label>
                <b-input
                  id="vehicle_registration_expiry"
                  v-model="transactionHistory.vehicle_registration_expiry"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="vehicle_seat_capacity">Seat Capacity</label>
                <b-input
                  id="vehicle_seat_capacity"
                  v-model="transactionHistory.vehicle_seat_capacity"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

            </b-tab>
          </b-tabs>
        </form>
      </b-card>

      <template #modal-footer="{cancel}">
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>

    <!-- modal preview image -->
    <b-modal
      id="modal-preview-image"
      scrollable
      no-close-on-backdrop
      :title="`Preview Attachment [${file.name}]`"
      size="lg"
    >
      <div class="d-flex justify-content-center">
        <b-img
          fluid
          :src="file.preview"
        />
      </div>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { LogisticsOfficerReportService, LogisticsOfficerExportService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'
import url from '@/mixins/url'
import Ripple from 'vue-ripple-directive'
import JsonExcel from 'vue-json-excel'

export default {
  name: 'LogisticsOfficerTransactionHistories',

  middleware: ['auth', 'logistics-officer'],

  metaInfo () {
    return {
      title: 'Reports - Transaction Histories'
    }
  },

  components: {
    JsonExcel
  },

  directives: {
    Ripple
  },

  mixins: [formatter, url],

  data () {
    return {
      state: {
        editing: false,
        busy: false,
        exporting: false
      },
      file: {
        name: '',
        preview: null
      },
      list: {
        statuses: [
          { text: 'All', value: 'All' },
          { text: 'Approved', value: 'Approved' },
          { text: 'Disapproved', value: 'Disapproved' }
        ],
        reservationTypes: [
          { text: 'All', value: 'All' },
          { text: 'Service Driver', value: 'Service Driver' },
          { text: 'Service Vehicle', value: 'Service Vehicle' },
          { text: 'Service Driver And Vehicle', value: 'Service Driver And Vehicle' }
        ],
        companies: []
      },
      filter: {
        status: this.$route.params?.status || 'All',
        reservation_type: 'All',
        company: 'All',
        date_from: this.dateFormatter(Date.now()),
        date_to: this.dateFormatter(Date.now())
      },
      transactionHistory: {
        id: 0,
        created_at: '',
        status: '',
        user_name: '',
        company_name: '',
        organization_name: '',
        reservation_type: '',
        departure_date: '',
        departure_time: '',
        arrival_date: '',
        arrival_time: '',
        vehicle_type_name: '',
        passenger_count: 0,
        purpose: '',
        vehicle_name: '',
        vehicle_license_plate: '',
        vehicle_registration_expiry: '',
        vehicle_seat_capacity: 0,
        driver_name: '',
        driver_license_type: '',
        driver_license_number: '',
        driver_license_expiry: '',
        processed_at: '',
        processed_by_name: '',
        file_path: '',
        attachments: []
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', label: 'reserve at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'user_name', label: 'reserved by' },
          { key: 'company_name', label: 'company' },
          { key: 'organization_name', label: 'organization' },
          { key: 'reservation_type' },
          { key: 'vehicle_type_name', label: 'vehicle type' },
          'departure',
          'arrival',
          { key: 'status' },
          { key: 'processed_at' },
          { key: 'processed_by_name', label: 'processed_by' }
        ]
      },
      attachmentTableSettings: {
        fields: [
          'actions',
          { key: 'file_original_name', label: 'filename' }
        ]
      },
      exportAs: {
        excel: {
          header: 'Reservation Histories',
          settings: {
            type: 'xls',
            escape: false
          },
          fields: {
            Company: {
              field: 'company_name'
            },
            Organization: {
              field: 'organization_name'
            },
            Employee: {
              field: 'user_name'
            },
            'Reservation Status': {
              field: 'status'
            },
            'Reservation Type': {
              field: 'reservation_type'
            },
            'Reservation Purpose': {
              field: 'purpose'
            },
            'Passenger Count': {
              field: 'passenger_count'
            },
            'Reserve At': {
              field: 'created_at',
              callback: value => `${this.dateTimeFormatter(value)}`
            },
            Vehicle: {
              field: 'vehicle_name'
            },
            'Vehicle Type': {
              field: 'vehicle_type_name'
            },
            'Vehicle Plate': {
              field: 'vehicle_license_plate'
            },
            'Vehicle Registration Expiry': {
              field: 'vehicle_registration_expiry'
            },
            Driver: {
              field: 'driver_name'
            },
            'Driver License': {
              field: 'driver_license_number'
            },
            'Driver License Type': {
              field: 'driver_license_type'
            },
            'Driver License Expiry': {
              field: 'driver_license_expiry'
            },
            'Departure Date': {
              field: 'departure_date'
            },
            'Departure Time': {
              field: 'departure_time'
            },
            'Arrival Date': {
              field: 'arrival_date'
            },
            'Arrival Time': {
              field: 'arrival_time'
            },
            'Processed By': {
              field: 'processed_by_name'
            },
            'Processed Remarks': {
              field: 'processed_remarks'
            },
            'Processed At': {
              field: 'processed_at',
              callback: value => `${this.dateTimeFormatter(value)}`
            }
          }
        }
      }
    }
  },

  computed: {
    departureDateTime () {
      if (this.transactionHistory.departure_date !== '' && this.transactionHistory.departure_time !== '') {
        return this.dateTimeFormatterLong(`${this.transactionHistory.departure_date} ${this.transactionHistory.departure_time}`)
      }
      return ''
    },

    arrivalDateTime () {
      if (this.transactionHistory.arrival_date !== '' && this.transactionHistory.arrival_time !== '') {
        return this.dateTimeFormatterLong(`${this.transactionHistory.arrival_date} ${this.transactionHistory.arrival_time}`)
      }
      return ''
    },

    filterCompanies () {
      return [].concat({ text: 'All', value: 'All' }, this.list.companies)
    },

    excelFileName () {
      return `${this.filter.status}-Reservations-${this.filter.date_from.replaceAll('-', '')}-${this.filter.date_to.replaceAll('-', '')}.xls`
    },

    isDriverVisible () {
      return ['Service Driver', 'Service Driver And Vehicle'].includes(this.transactionHistory.reservation_type)
    },

    isVehicleVisible () {
      return ['Service Vehicle', 'Service Driver And Vehicle'].includes(this.transactionHistory.reservation_type)
    }
  },

  mounted () {
    core.index()
    this.fetchCompanyList()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await LogisticsOfficerReportService.getTransactionHistories(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=asc&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}&filter_status=${this.filter.status}&filter_type=${this.filter.reservation_type}&filter_company=${this.filter.company}&filter_from=${this.filter.date_from}&filter_to=${this.filter.date_to}`)
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async myExporter () {
      try {
        const { data } = await LogisticsOfficerExportService.getTransactionHistories(`filter_text=${this.tableSettings.filter}&filter_status=${this.filter.status}&filter_type=${this.filter.reservation_type}&filter_company=${this.filter.company}&filter_from=${this.filter.date_from}&filter_to=${this.filter.date_to}`)
        return data
      } catch {
        this.state.exporting = false
        return []
      }
    },

    async fetchCompanyList () {
      await SharedListService.getCompanies().then(({ data }) => {
        this.list.companies = data.map(company => ({
          text: company.name,
          value: company.id
        }))
      })
    },

    onFilterChange () {
      this.$refs.table.refresh()
    },

    startExport () {
      this.state.exporting = true
    },

    finishExport () {
      this.state.exporting = false
    },

    onShowViewForm (item) {
      this.transactionHistory.id = item.id
      this.transactionHistory.created_at = this.dateTimeFormatter(item.created_at)
      this.transactionHistory.status = item.status
      this.transactionHistory.user_name = item.user_name
      this.transactionHistory.company_name = item.company_name
      this.transactionHistory.organization_name = item.organization_name
      this.transactionHistory.reservation_type = item.reservation_type
      this.transactionHistory.departure_date = item.departure_date
      this.transactionHistory.departure_time = item.departure_time
      this.transactionHistory.arrival_date = item.arrival_date
      this.transactionHistory.arrival_time = item.arrival_time
      this.transactionHistory.vehicle_type_name = item.vehicle_type_name
      this.transactionHistory.passenger_count = item.passenger_count
      this.transactionHistory.purpose = item.purpose
      this.transactionHistory.vehicle_name = item.vehicle_name
      this.transactionHistory.vehicle_license_plate = item.vehicle_license_plate
      this.transactionHistory.vehicle_registration_expiry = item.vehicle_registration_expiry
      this.transactionHistory.vehicle_seat_capacity = item.vehicle_seat_capacity
      this.transactionHistory.driver_name = item.driver_name
      this.transactionHistory.driver_license_type = item.driver_license_type
      this.transactionHistory.driver_license_number = item.driver_license_number
      this.transactionHistory.driver_license_expiry = item.driver_license_expiry
      this.transactionHistory.processed_by_name = item.processed_by_name
      this.transactionHistory.processed_at = this.dateTimeFormatter(item.processed_at)
      this.transactionHistory.file_path = item.file_path
      this.transactionHistory.attachments = item.attachments
      this.$bvModal.show('modal-view-form')
    },

    onPreviewAttachment (item) {
      this.file.name = item.file_original_name
      this.file.preview = this.getImageUrl(`${this.transactionHistory.file_path}/${item.file_hash_name}`)
      this.$bvModal.show('modal-preview-image')
    }
  }
}
</script>
